import { createApp } from "vue"
import ElementPlus from "element-plus"
import * as ElementPlusIconsVue from "@element-plus/icons-vue"
import "element-plus/dist/index.css"
import App from "./App.vue"
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import pinia from './store/pinia'
import { useRoute } from "./store/route.state"

console.log("import.meta\u200b.env.VITE_MODE:", import.meta.env.VITE_MODE)
console.log("import.meta\u200b.env.VITE_MOCKJS:", import.meta.env.VITE_MOCKJS)
console.log("import.meta\u200b.env.VITE_API_BASE_URL:", import.meta.env.VITE_API_BASE_URL)
console.log("import.meta\u200b.env.CURRENT_TIME:", import.meta.env.CURRENT_TIME)

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(pinia)
app.use(ElementPlus, {
    locale: zhCn,
})

// app.use(router)
const route = useRoute()
app.use(route.router)

app.mount("#app")
