import { RouteRecordRaw } from 'vue-router'
import { ICON_SVG } from '../icon.svg'
import { InternalRootView, InternalSubView, component } from '../utils/route.utils'

export const ChannelName = 'user'
export const ChannelLabel = '用户'
export const UserRoute: RouteRecordRaw = {
  name: 'user',
  path: '/user',
  component: InternalRootView,
  meta: {
    label: '用户管理',
    icon: 'UserFilled',
    group: { name: ChannelName, label: ChannelLabel },
    href: () => {
      return `/user`
    },
    showInLeftMenu: true,
    svg: ICON_SVG.goods
  },
  children: [
    {
      name: 'user-search',
      path: '/user',
      component: component(ChannelName, 'UserListView'),
      meta: {
        label: '查询',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        href: () => {
          return `/user`
        },
        showInLeftMenu: true,
        svg: ICON_SVG.goods
      }
    },
    {
      name: 'user-new',
      path: '/user/new',
      component: component(ChannelName, 'UserDetailView'),
      meta: {
        label: '新增',
        icon: 'DocumentAdd',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: true,
        svg: ICON_SVG.goods,
        href: () => {
          return `/user`
        }
      }
    },
    {
      name: 'user-edit',
      path: '/user/:id(\\d+)',
      component: component(ChannelName, 'UserDetailView'),
      meta: {
        label: '详情',
        icon: 'SetUp',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        svg: ICON_SVG.goods,
        href: (d: any) => {
          if (d === undefined) {
            return '/home'
          }
          console.log('routes href:', d)
          return `/user/${d.id}`
        }
      }
    },
    {
      name: 'user-change-password',
      path: '/user/change/password',
      component: component(ChannelName, 'UserChangePassword'),
      meta: {
        label: '修改密码',
        icon: 'SetUp',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        svg: ICON_SVG.goods,
        href: (d: any) => {
          if (d === undefined) {
            return '/user'
          }
          console.log('routes href:', d)
          return `/user/${d.id}/change-password`
        }
      }
    },
    {
      name: 'user-base-info',
      path: '/user/base/info',
      component: component(ChannelName, 'UserBaseInfo'),
      meta: {
        label: '基本信息',
        icon: 'SetUp',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        svg: ICON_SVG.goods,
        href: () => ''
      }
    }
  ]
}
