import { component } from '../utils/route.utils'

import { ICON_SVG } from '../icon.svg'
import { RouteRecordRaw } from 'vue-router'

export const ChannelName = 'lxb/creditcard/promotion/reconciliation'
export const ChannelLabel = '信用卡开卡导入'

const componentPath = (path?: string) => {
    if (path === undefined || path === null) {
        return ChannelName
    }

    return `${ChannelName}/${path}`
}

export const CreditCardPromotionReconciliationRoute: RouteRecordRaw = {
    name: ChannelName,
    path: `/${ChannelName}`,
    meta: {
        label: '信用卡开卡导入',
        icon: 'CreditCard',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: true,
        href: (d: any) => {
            return `/${ChannelName}/0`
        },
        svg: ICON_SVG.bank
    },
    children: [
        {
            name: 'credit-card-promotion-reconciliation-main',
            path: `/${ChannelName}`,
            component: component(componentPath(), 'List'),
            meta: {
                label: '列表',
                icon: 'Search',
                group: { name: ChannelName, label: ChannelLabel },
                showInLeftMenu: false,
                href: (d: any) => {
                    return `/${ChannelName}`
                },
                svg: ICON_SVG.bank
            },
        },
        {
            name: 'credit-card-promotion-reconciliation-import',
            path: `/${ChannelName}/import`,
            component: component(componentPath(), 'Import'),
            meta: {
                label: '导入',
                icon: 'Search',
                group: { name: ChannelName, label: ChannelLabel },
                showInLeftMenu: false,
                href: (d: any) => {
                    return `/${ChannelName}/condition/${d.id}`
                },
                svg: ICON_SVG.bank
            }
        },
        {
            name: 'credit-card-promotion-reconciliation-detail',
            path: `/${ChannelName}/:id`,
            component: component(componentPath(), 'Detail'),
            meta: {
                label: '详情',
                icon: 'Search',
                group: { name: ChannelName, label: ChannelLabel },
                showInLeftMenu: false,
                href: (d: any) => {
                    return `/${ChannelName}/condition/${d.id}`
                },
                svg: ICON_SVG.bank
            }
        }
    ]
}
