import { RouteRecordRaw } from 'vue-router'
import { ICON_SVG } from '../icon.svg'
import { InternalRootView, component } from '../utils/route.utils'

export const ChannelName = 'resource'
export const ChannelLabel = '资源'
export const ResourceRoute: RouteRecordRaw = {
  name: `${ChannelName}`,
  path: `/${ChannelName}`,
  meta: {
    label: `${ChannelLabel}管理`,
    icon: 'User',
    group: { name: ChannelName, label: ChannelLabel },
    href: () => {
      return `/${ChannelName}`
    },
    showInLeftMenu: true,
    svg: ICON_SVG.goods
  },
  children: [
    {
      name: `${ChannelName}`,
      path: `/${ChannelName}`,
      component: component(ChannelName, 'ResourceTable'),
      meta: {
        label: '资源列表',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        href: () => {
          return `/${ChannelName}`
        },
        showInLeftMenu: false,
        svg: ICON_SVG.goods
      }
    },
    {
      name: `${ChannelName}-detail`,
      path: `/${ChannelName}-detail`,
      component: component(ChannelName, 'ResourceDetail'),
      meta: {
        label: '新增',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        href: () => {
          return `/${ChannelName}`
        },
        showInLeftMenu: false,
        svg: ICON_SVG.goods
      }
    },
    {
      name: `${ChannelName}-update`,
      path: `/${ChannelName}-update/:id`,
      component: component(ChannelName, 'ResourceDetail'),
      meta: {
        label: '编辑',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        href: () => {
          return `/${ChannelName}`
        },
        showInLeftMenu: false,
        svg: ICON_SVG.goods
      }
    }
  ]
}
