
import { LoginRoute } from './access/login.route'
import { RouteRecordRaw } from 'vue-router'
import { UserRoute } from './access/user.route'
import { HomeRoute } from './home.route'
import { AccessRoute } from './access/access.route'
import { AgentRoute } from './dfq/agent.route'
import { AgentOrderRoute } from './dfq/agent.order.route'
import { AgentUserRoute } from './dfq/agent.user.route'
import { DfqRoute } from './dfq'
import { LxbRoute } from './lxb/index'
import { ProductRoute } from './product'
const system = import.meta.env.VITE_SYSTEM_TYPE

export const GlobalRoutes: RouteRecordRaw[] =
  system == 'admin'
    ? [LoginRoute, HomeRoute, DfqRoute, LxbRoute, ProductRoute, UserRoute, AccessRoute]
    : [LoginRoute, HomeRoute, AgentRoute, AgentOrderRoute, AgentUserRoute]
