import { ICON_SVG } from '../icon.svg'
import { RouteRecordRaw } from 'vue-router'
import { InternalSubView, InternalRootView, component } from '../utils/route.utils'

export const ChannelName = 'goods'
export const ChannelLabel = '商品'
export const GoodsRoute: RouteRecordRaw = {
  name: 'goods',
  path: '/goods',
  meta: {
    label: '商品管理',
    icon: 'Goods',
    group: { name: ChannelName, label: ChannelLabel },
    href: () => {
      return `/goods`
    },
    showInLeftMenu: true,
    svg: ICON_SVG.goods
  },
  children: [
    {
      name: 'goods-search',
      path: '/goods',
      component: component(ChannelName, 'GoodsListView'),
      meta: {
        label: '查询',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        href: () => {
          return `/goods`
        },
        showInLeftMenu: false,
        cache: true
      }
    },
    {
      name: 'goods-new',
      path: '/goods/new',
      component: component(ChannelName, 'GoodsDetailView'),
      meta: {
        label: '新增',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        href: () => {
          return `/goods/new`
        }
      }
    },
    {
      name: 'goods-detail',
      path: '/goods/:id(\\d+)',
      component: component(ChannelName, 'GoodsDetailView'),
      meta: {
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        href: (d: any) => {
          return `/goods/${d.productId}/${d.id}`
        },
        label: '详情',
        showInLeftMenu: false,
        svg: ICON_SVG.goods
      }
    }
  ]
}
