import { InternalSubView, InternalRootView, component } from '../utils/route.utils'

import { ICON_SVG } from '../icon.svg'
import { RouteRecordRaw } from 'vue-router'

export const ChannelName = 'notice'
export const ChannelLabel = '公告'
export const NoticeRoute: RouteRecordRaw = {
  name: ChannelName,
  path: `/dfq/${ChannelName}`,
  meta: {
    label: `${ChannelLabel}管理`,
    icon: 'OfficeBuilding',
    group: { name: ChannelName, label: ChannelLabel },
    showInLeftMenu: true,
    href: (d: any) => {
      return `/${ChannelName}`
    },
    svg: ICON_SVG.bank
  },
  children: [
    {
      name: `${ChannelName}-search`,
      path: `/dfq/${ChannelName}`,
      component: component(ChannelName, 'NoticeList'),
      meta: {
        label: '查询',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        href: (d: any) => {
          return `/${ChannelName}`
        },
        svg: ICON_SVG.bank
      }
    },
    {
      name: `${ChannelName}-new`,
      path: `/${ChannelName}/new`,
      component: component(ChannelName, 'NoticeEdit'),
      meta: {
        label: '新增',
        icon: 'DocumentAdd',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        href: (d: any) => {
          if (d === undefined) {
            return '/bank'
          }

          return `/${ChannelName}/new `
        },
        svg: ICON_SVG.product
      }
    },
    {
      name: `${ChannelName}-edit`,
      path: `/${ChannelName}/:id(\\d+)`,
      component: component(ChannelName, 'NoticeEdit'),
      meta: {
        label: '编辑',
        icon: 'DocumentChecked',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        href: (d: any) => {
          if (d === undefined) {
            return `/${ChannelName}`
          }

          return `/${ChannelName}/${d.bankId} `
        },
        svg: ICON_SVG.product
      }
    }
  ]
}
