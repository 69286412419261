import { ICON_SVG } from '../icon.svg'
import { RouteRecordRaw } from 'vue-router'
import { InternalRootView, InternalSubView, component } from '../utils/route.utils'

export const ChannelName = 'dfq/product'
export const ChannelLabel = '产品'
export const ProductRoute: RouteRecordRaw = {
  name: 'product',
  path: `/${ChannelName}`,
  meta: {
    href: (d: any) => {
      return '/product'
    },
    label: '产品管理',
    icon: 'ShoppingBag',
    group: { name: ChannelName, label: ChannelLabel },
    showInLeftMenu: true,
    svg: ICON_SVG.product
  },
  children: [
    {
      name: 'product-search',
      path: `/${ChannelName}`,
      component: component(ChannelName, 'ProductListView'),
      meta: {
        href: (d: any) => {
          return '/product'
        },
        label: '查询',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        svg: ICON_SVG.product,
        cache: true
      }
    },
    {
      name: 'product-new',
      path: `/${ChannelName}/new`,
      component: component(ChannelName, 'ProductDetailView'),
      meta: {
        href: (d: any) => {
          return 'product-list-by-product-id'
        },
        label: '新增',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false
      }
    },
    {
      name: 'product-edit',
      path: `/${ChannelName}/:productId(\\d+)`,
      component: component(ChannelName, 'ProductDetailView'),
      meta: {
        href: (d: any) => {
          return 'product-list-by-product-id'
        },
        label: '详情',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false
      }
    },
    {
      path: `/${ChannelName}/:productId(\\d+)/goods`,
      name: 'product-goods',
      component: component(ChannelName, 'ProductGoodsListView'),
      meta: {
        label: '商品',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        href: (d: any) => {
          console.log('product route, d:', d)
          if (d === undefined) {
            return `/goods`
          }

          return `/goods/${d.productId}`
        },
        showInLeftMenu: false,
        svg: ICON_SVG.goods
      }
    }
  ]
}
