import { component } from '../utils/route.utils'

import { ICON_SVG } from '../icon.svg'
import { RouteRecordRaw } from 'vue-router'

export const ChannelName = 'lxb/commission'
export const ChannelLabel = '级别'

const componentPath = (path?: string) => {
  if (path === undefined || path === null) {
    return ChannelName
  }

  return `${ChannelName}/${path}`
}

export const levelRoute: RouteRecordRaw = {
  name: ChannelName,
  path: `/${ChannelName}`,
  meta: {
    label: '团队佣金',
    icon: 'Operation',
    group: { name: ChannelName, label: ChannelLabel },
    showInLeftMenu: true,
    href: (d: any) => {
      return `/${ChannelName}/0`
    },
    svg: ICON_SVG.bank
  },
  children: [
    {
      name: 'comission-main',
      path: `/${ChannelName}`,
      component: component(componentPath(), 'Tabs'),
      meta: {
        label: '列表',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        href: (d: any) => {
          return `/${ChannelName}`
        },
        svg: ICON_SVG.bank
      },
    },
    {
      name: 'comission-reward-type',
      path: `/${ChannelName}/:id(\\d+)`,
      component: component(ChannelName, 'Tabs'),
      meta: {
        label: '列表',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        href: (d: any) => {
          return `/${ChannelName}`
        },
        svg: ICON_SVG.bank
      },
    },
    {
      name: 'level-edit',
      path: `/${ChannelName}/level/:id`,
      component: component(componentPath(), 'LevelDetail'),
      meta: {
        label: '编辑',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        href: (d: any) => {
          return `/${ChannelName}/${d.id}`
        },
        svg: ICON_SVG.bank
      }
    },
    {
      name: 'condition-detail',
      path: `/${ChannelName}/condition/:id`,
      component: component(componentPath(), 'RewardDetail'),
      meta: {
        label: '活动详情',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        href: (d: any) => {
          return `/${ChannelName}/condition/${d.id}`
        },
        svg: ICON_SVG.bank
      }
    }
  ]
}
