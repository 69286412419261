import { InternalSubView, InternalRootView, component } from '../utils/route.utils'

import { ICON_SVG } from '../icon.svg'
import { RouteRecordRaw } from 'vue-router'

export const ChannelName = 'lxb/fee'
export const ChannelLabel = '费率'

export const FeeRoute: RouteRecordRaw = {
  name: ChannelName,
  path: `/${ChannelName}`,
  component: component(ChannelName, 'Fee'),
  meta: {
    label: '费率管理',
    icon: 'OfficeBuilding',
    group: { name: 'lxb/fee', label: '费率管理' },
    showInLeftMenu: true,
    href: (d: any) => {
      return '/lxb/fee'
    },
    svg: ICON_SVG.bank
  },
  children: [
    {
      name: 'fee-new',
      path: `/${ChannelName}/new`,
      component: component(ChannelName, 'FeeDetail'),
      meta: {
        label: '新增',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        href: (d: any) => {
          return `/${ChannelName}`
        },
        svg: ICON_SVG.bank
      }
    },
    {
      name: 'fee-edit',
      path: `/${ChannelName}/:id`,
      component: component(ChannelName, 'FeeDetail'),
      meta: {
        label: '编辑',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        href: (d: any) => {
          return `/${ChannelName}`
        },
        svg: ICON_SVG.bank
      }
    }
  ]
}
